/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type KeyDates_query = {
    readonly openedReportingYear: {
        readonly swrsDeadline: string;
        readonly applicationOpenTime: string;
        readonly applicationCloseTime: string;
    } | null;
    readonly nextReportingYear: {
        readonly swrsDeadline: string;
        readonly applicationOpenTime: string;
        readonly applicationCloseTime: string;
    } | null;
    readonly " $refType": "KeyDates_query";
};
export type KeyDates_query$data = KeyDates_query;
export type KeyDates_query$key = {
    readonly " $data"?: KeyDates_query$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"KeyDates_query">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "swrsDeadline",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "applicationOpenTime",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "applicationCloseTime",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "KeyDates_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportingYear",
      "kind": "LinkedField",
      "name": "openedReportingYear",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportingYear",
      "kind": "LinkedField",
      "name": "nextReportingYear",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '17a3d9e1f1acc2ef7d472497390361f7';
export default node;
