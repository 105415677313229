/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RegistrationLoginButtons_query = {
    readonly openedReportingYear: {
        readonly applicationCloseTime: string;
    } | null;
    readonly " $refType": "RegistrationLoginButtons_query";
};
export type RegistrationLoginButtons_query$data = RegistrationLoginButtons_query;
export type RegistrationLoginButtons_query$key = {
    readonly " $data"?: RegistrationLoginButtons_query$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"RegistrationLoginButtons_query">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RegistrationLoginButtons_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportingYear",
      "kind": "LinkedField",
      "name": "openedReportingYear",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "applicationCloseTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = 'c05cfd400d772ebe3af65044669a3956';
export default node;
