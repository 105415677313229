/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type pagesQueryVariables = {};
export type pagesQueryResponse = {
    readonly query: {
        readonly session: {
            readonly " $fragmentRefs": FragmentRefs<"defaultLayout_session">;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"KeyDates_query" | "RegistrationLoginButtons_query">;
    };
};
export type pagesQuery = {
    readonly response: pagesQueryResponse;
    readonly variables: pagesQueryVariables;
};



/*
query pagesQuery {
  query {
    session {
      ...defaultLayout_session
    }
    ...KeyDates_query
    ...RegistrationLoginButtons_query
    id
  }
}

fragment KeyDates_query on Query {
  openedReportingYear {
    swrsDeadline
    applicationOpenTime
    applicationCloseTime
    id
  }
  nextReportingYear {
    swrsDeadline
    applicationOpenTime
    applicationCloseTime
    id
  }
}

fragment RegistrationLoginButtons_query on Query {
  openedReportingYear {
    applicationCloseTime
    id
  }
}

fragment UserProfileDropdown_user on CiipUser {
  firstName
  lastName
  emailAddress
}

fragment defaultLayout_session on JwtToken {
  ciipUserBySub {
    ...UserProfileDropdown_user
    id
  }
  userGroups
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "swrsDeadline",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "applicationOpenTime",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "applicationCloseTime",
    "storageKey": null
  },
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "pagesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Query",
        "kind": "LinkedField",
        "name": "query",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JwtToken",
            "kind": "LinkedField",
            "name": "session",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "defaultLayout_session"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "KeyDates_query"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RegistrationLoginButtons_query"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "pagesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Query",
        "kind": "LinkedField",
        "name": "query",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JwtToken",
            "kind": "LinkedField",
            "name": "session",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CiipUser",
                "kind": "LinkedField",
                "name": "ciipUserBySub",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emailAddress",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userGroups",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ReportingYear",
            "kind": "LinkedField",
            "name": "openedReportingYear",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ReportingYear",
            "kind": "LinkedField",
            "name": "nextReportingYear",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec339ba1a421e5ff6f1bd1c95e39b8d6",
    "id": null,
    "metadata": {},
    "name": "pagesQuery",
    "operationKind": "query",
    "text": "query pagesQuery {\n  query {\n    session {\n      ...defaultLayout_session\n    }\n    ...KeyDates_query\n    ...RegistrationLoginButtons_query\n    id\n  }\n}\n\nfragment KeyDates_query on Query {\n  openedReportingYear {\n    swrsDeadline\n    applicationOpenTime\n    applicationCloseTime\n    id\n  }\n  nextReportingYear {\n    swrsDeadline\n    applicationOpenTime\n    applicationCloseTime\n    id\n  }\n}\n\nfragment RegistrationLoginButtons_query on Query {\n  openedReportingYear {\n    applicationCloseTime\n    id\n  }\n}\n\nfragment UserProfileDropdown_user on CiipUser {\n  firstName\n  lastName\n  emailAddress\n}\n\nfragment defaultLayout_session on JwtToken {\n  ciipUserBySub {\n    ...UserProfileDropdown_user\n    id\n  }\n  userGroups\n}\n"
  }
};
})();
(node as any).hash = '50b9cc7a6dd2ad885a54fe80e4b406a9';
export default node;
